.radio_line{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
  .line_title_span{
    width: 20%;
    font-size: 14px;
    font-weight: 600;
    color: #000;
  }
  .line_radio_div{
    width: 20%;
    text-align: center;
  }
  .line_select_div{
    width: 50%;
    >div{
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      >select{
        width: 120px;
        border: none;
        border-bottom: 1px solid #d9d9d9;
      }
      >div{
        width: 120px;
        border-bottom: 1px solid #d9d9d9;
        input{
          width: 89%;
          border: none;
        }
      }
    }
    >input{
      width: 100%;
      border:none;
      border-bottom: 1px solid #d9d9d9;
    }
  }
  :global(.ant-space-item){
    text-align: center;
    label{
      margin-right: 0 !important;
      margin: 0 auto;
    }
    span{
      font-size: 12px;
      color: #000;
    }
  }
  *:disabled{
    color: #bbb;
    background-color: white;
    &::placeholder{
      color: #ccc;
    }
  }
}
.memo_section{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
  .memo_title_p{
    width: 20%;
    font-size: 14px;
    font-weight: 600;
    color: #000;
  }
  .memo_desc_p{
    width: 75%;
    font-size: 12px;
    text-align: left;
    color: #767676;
  }
  .memo_desc_input{
    flex:0.95;
    min-height: 100px;
    padding: 10px;
    font-size: 12px;
    color: #767676;
    border: 1px solid #d9d9d9;
  }
}
.option_button_div{
  margin-top: 100px;
  text-align: center;
  button{
    width: 120px;
    padding: 8px 34px;
    border-radius: 20px;
    border: solid 2px #1e3d83;
  }
  .reset_button{
    margin-right: 10px;
    background-color: #fff;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: #1e3d83;
  }
  .save_button{
    background-color: #1e3d83;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: #fff;
  }
}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;