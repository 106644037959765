.login_wrapper {
  width: 90%;
  max-width: 500px;
  height: 600px;
  background-color: #d9d9d9;
  padding: 80px;
  box-shadow: 5px 5px 10px rgba(0,0,0,0.3);
  border-radius: 0 10px 10px 0;
  h2 {
    color: #4c72f1;
    font-size: 34px;
    margin-bottom: 30px;
    letter-spacing: -0.1rem;
  }

  .input {
    height: 50px;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: rgba(0,0,0,0);
    border-bottom: 1px solid #999;
    ::placeholder{
      color: #767676;
    }
    :global(.ant-input){
      background-color: rgba(0,0,0,0) ;
    }
    :global(.ant-input-prefix) {
      padding-left: 10px;
      font-size: 14px;
      font-weight: 500;
      min-width: 100px;
      color: #999;
      letter-spacing: -0.01rem;
    }
  }

  .submit {
    margin-top: 15px;
    height: 60px;
    border-radius: 50px;
  }
}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;