.slider_div {
    position: relative;
    height: 360px;
    background-image: url(../../../../src/assets/dummy/01/01-01.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px;
    overflow: hidden;
    .slider_inner_div {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 10px 20px;
    }
  }
  .category_p {
    font-size: 12px;
    color: #fff;
  }
  .title_p {
    margin: 2px 0 29px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
  }
  .flex_div {
    display: flex;
    justify-content: space-between;
  }
  .style_profile_img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }
  .style_button_img {
    width: 20px;
    height: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  .style_button_count_p {
    font-size: 12px;
    font-weight: 500;
    line-height: 2;
    letter-spacing: -0.6px;
    color: #fff;
  }
  .style_condition_div{
      width: 72px;
      height: 72px;
      padding: 13.7px;
      border-radius: 10px;
      background-color: #1e3d83;
      text-align: center;
  }
  .style_condition_p{
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      color: #fff;
      white-space: nowrap;
  }
  .style_condition_label_span{
      width: 72px;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      color: #1e3d83;
  }
  .style_condition_list{
      margin-top: 30px;
      ul{
          list-style: none;
          padding-left: 0;
          li{
              display: flex;
              align-items: center;
              margin: 5px 0px;
              span:first-child{
                  margin-right: 5px;
                  width: 5px;
                  height: 5px;
                  border-radius: 50%;
                  background-color: #767676;
              }
              span:last-child{
                  font-size: 14px;
                  text-align: left;
                  color: #767676;
              }
          }
      }
  }
  
  
@primary-color: #1e3d83;@table-gray-color: #e8e8e8;