.alignInline {
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkBtn {
  background-color: #d8d8d8;
  color: #fff;
  border: none;
}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;