.category_div {
  width: 840px;
  height: 1030px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 40px 36px 23px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
}

//index
.category_div {
  background-color: white;

  :global(.ant-row) {
    width: 100%;
    margin: 0 !important;
    display: inline-block;
    height: 100%;
  }
  :global(.ant-col-10) {
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0 !important;
    height: 100%;
  }
  :global(.ant-card) {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
  }
  :global(.ant-card-body) {
    width: 100%;
    height: 100%;
  }
  .tree_container_div {
    height: 100%;
    :global(.ant-tree) {
      height: 100%;
    }
    :global(.ant-tree-list) {
      height: 100%;
    }
    :global(.ant-tree-list-holder) {
      height: 100% !important;
    }
  }
}
.switchWrapper {
  transform: scaleX(-1);
}
.filterWrapper {
  display: flex;
  align-items: center;
  margin-left: 20px;
  .filterText {
    margin-left: 4px;
    font-size: 14px;
    color: #767676;
  }
}
.switchWrapper {
  transform: scaleX(-1);
}
.colBoldText {
  font-size: 12px;
  font-weight: bold;
}

.colText {
  font-size: 12px;
  color: #767676;
}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;