.alignInline {
  display: flex;
  align-items: center;
}
.profileWrapper {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #efefef;
  background-size: cover;
  background-position: center;
}
.statusLabelWrapper {
  width: 38px;
  height: 18px;
  padding: 3px 7px;
  border-radius: 3px;
  background-color: #1e3d83;
  display: flex;
  align-items: center;
  justify-content: center;
}
.deletebuttonwrapper {
  cursor: pointer;
  right: -5px;
  top: -5px;
  background-color: #1e3d83;
  border-radius: 50%;
  display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  position: absolute;
}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;