.radioBox {
  display: flex;
  align-items: baseline;
}
.radioTitle {
  font-size: 16px;
  margin-bottom: 0;
}
.radioTextBox {
  padding-left: 5px;
}
.feeBox {
  text-align: center;
}
th {
  font-weight: 400;
}
.iconBox {
  display: flex;
  align-items: center;
}
table {
  width: 100%;
}
.arange {
  text-align: inherit;
}
.secondCol {
  text-align: end;
  padding-right: 40px;
}
.bottomFitstColContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 35px;
}
.bottomSecondColContainer {
  padding-left: 35px;
}
.input {
  text-align: end;
}
.inputTitle {
  margin-bottom: 0;
  margin: 5px 0;
}
// .bottomLeftBox {
//   margin-right: 35px;
// }
// .bottomRightBox {
//   margin-left: 35px;
// }
// .title {
//   margin: 10px 0;
// }
// .contents {
//   display: flex;
//   justify-content: center;
//   text-align: center;
//   align-items: end;
// }

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;