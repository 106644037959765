.container {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  position: relative;
}
.deleteButtonWrapper {
  cursor: pointer;
  width: 17px;
  height: 17px;
  position: absolute;
  top: -3px;
  right: -7px;
  border-radius: 50%;
  z-index: 1;
  background-color: #1e3d83;
  display: flex;
  align-items: center;
  justify-content: center;
}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;