.cardWrapper {
  width: 320px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid blue;
}
.content_div {
  position: relative;
  //width: 100%;
  cursor: pointer;
  height: 100%;
  padding: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  .contentWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .image {
    width: 65px;
    height: 65px;
    margin-top: 20px;
  }
  .titleWrapper {
    position: relative;
    width: fit-content;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .title {
    font-size: 12px;
    color: #767676;
    align-self: flex-start;
  }
  .subTitle {
    font-size: 18px;
    color: #000;
    line-height: 1;
    font-weight: bold;
    letter-spacing: -0.9px;
  }
  .countWrapper {
    margin-top: 18px;
  }
  .mainCountText {
    font-size: 24px;
    font-weight: 900;
    color: #1e3d83;
    line-height: 1;
  }
  .label {
    font-size: 14px;
    line-height: 1.57;
    height: 22px;
    color: #767676;
  }
}
.container {
  height: 380px;
width: 380px;
  margin-bottom: 30px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  &:hover {
    .background {
      opacity: 1;
    }

    .home_slider :global(.slick-active ::before) {

      color: #fff;
    }
    .content_div {
      .title,
      .subTitle,
      .mainCountText,
      .label {
        color: #fff;
      }
      .image {
        filter: brightness(0) invert(1);
      }
    }
  }
  .background {
    width: 100%;
    height: inherit;
    position: absolute;
    opacity: 0;
    z-index: 1;
    transition: opacity 0.3s ease-in-out;
    background-image: radial-gradient(circle at 50% 50%, #335cb8, #1e3d83 62%);
  }
  .home_slider {
    z-index: 99;
    width: 100%;
    height: 100%;
    position: relative;
    :global(.slick-dots) {
      position: absolute;
      top: 85%;
      gap: 10;
    }
    :global(.slick-active ::before) {
      color: #1e3d83;
    }
    :global(.slick-dots li) {
      margin-right: -5px;
      margin-left: -5px;
    }
  }
}
.arrow {
  position: absolute;
  bottom: 0;
  margin-bottom: 30px;
  z-index: 99;
  width: 26px;
  height: 26px;
  border-radius: 13px;
  background-color: #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.arrowIcon {
  width: 3.9px;
  height: 8px;
}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;