.buttonContainer {
  display: flex;
  width: 100%;
  height: 30px;
  border: solid 2px #1e3d83;
  background: #fff;
  border-radius: 15px;
  align-items: center;
  transition: 0.3s ease all;
  justify-content: center;

  cursor: pointer;
  .text {
    font-size: 12px;
    font-weight: 600;
    color: #1e3d83;
  }
}

.buttonContainer_reverseColor {
  display: flex;
  width: 100%;
  height: 30px;
  border: solid 2px #1e3d83;
  background: #1e3d83;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease all;

  cursor: pointer;
  .text {
    font-size: 12px;
    font-weight: 600;
    color: #fff;
  }
}
.buttonContainer_disabled {
  display: flex;
  width: 100%;
  height: 30px;
  border: solid 2px #1e3d83;
  background: #fff;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease all;

  cursor: not-allowed;
  .text {
    font-size: 12px;
    font-weight: 600;
    color: #1e3d83;
  }
}

.buttonContainer_delete {
  display: flex;
  width: 100%;
  height: 30px;
  border: solid 1.5px red;
  background: #fff;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease all;

  cursor: pointer;
  .text {
    font-size: 12px;
    font-weight: 600;
    color: red;
  }
}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;