.alignInline {
  display: flex;
  align-items: center;
  justify-content: center;
}
button {
  cursor: pointer;
}

.profileTable {
  tr {
    border-bottom: 1px solid #f2f2f2;
  }
  th {
    width: 120px;
    background-color: #f2f2f2;
    padding: 0;
  }

  .profileBtn {
    border: none;
    color: '#fff';
    height: 100%;
  }
  .active {
    border: none;
    color: #fff;
    height: 100%;
    background-color: #4c72f1;
  }
}

.alignColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
}
.checkBtn {
  background-color: #d8d8d8;
  color: #fff;
  border: none;
}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;