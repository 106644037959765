.contentTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span:first-child {
    margin-right: 20px;
  }
  span:nth-child(2) {
    font-weight: 700;
    font-size: 20px;
    margin-right: 10px;
  }
}
.subTitleRow {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #f2f2f2;
}
//tbody {
//  //height: 30px;
//}
//tbody > td {
//  font-size: 12px;
//}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;