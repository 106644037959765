.expert_setting_div {
  width: 840px;
  // height: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 40px 60px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;

  :global(.ant-radio-group) {
    width: 100%;
  }
  :global(.ant-space) {
    width: 100%;
  }
  :global(.ant-space-item) {
    width: 100%;
  }
  :global(.ant-radio-wrapper) {
    width: 100%;
    justify-content: center;
  }
  .sub_page_title_p {
    font-size: 16px;
    font-weight: 600;
    color: #000;
  }
  .title_span {
    margin-top: 60px;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #000;
  }
  .sub_title_span {
    display: inline-block;
    width: 300px;
    font-size: 12px;
    text-align: left;
    color: #767676;
  }
  .flex_three_div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0;
    input {
      border: none;
      border-bottom: 1px solid #000;
      width: 100px;
      &:disabled {
        background-color: rgba(0, 0, 0, 0.2);
        color: #666;
      }
    }
    & > * {
      text-align: center;
    }
    & > :first-child {
      width: 10%;
    }
    & > :nth-child(2) {
      width: 50%;
    }
    & > :nth-child(3) {
      width: 20%;
    }
    & > :nth-child(4) {
      width: 8%;
    }
  }
  .percentage_input {
    width: 90%;
    padding: 0px 50px;
    text-align: center;
    border: none;
    border-bottom: 1px solid #000;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
  .save_button{
    margin-left: auto;
    width: 120px;
    height: 30px;
    padding: 6px 30px 8px;
    border-radius: 15px;
    background-color: white;
    border: solid 2px #1e3d83;
    font-size: 12px;
    font-weight: 600;
    color: #1e3d83;
  }
}
.gray_text{
  font-size: 12px;
  color: #767676;
}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;