.modify_wrapper_div{
    padding: 50px 100px;
    .modify_input_wrapper_div{
        display: flex;
        border-bottom: 1px solid #d9d9d9;
        .modify_title_input{
            width: 90%;
            border: none;
            background-color: #f9f9f9;
            font-size: 16px;
            font-weight: 600;
            color: #000;
        }
        > p{
            width: 10%;
            text-align: center;
        }
    }
    .modify_select{
        display: block;
        margin: 15px auto;
        width: 300px;
        height: 35px;
        padding: 10px 12px 9px 10px;
        border: none;
        border-radius: 5px;
        background-color: #e8e8e8;
    }
    textarea{
        margin-top: 50px;
        width: 100%;
        border: none;
        background-color: #f9f9f9;
    }
    .label_wrapper_div{
        display: flex;
        flex-wrap: wrap;
        > p{
            margin: 0px 3.5px 7px;
            padding: 3px 10px;
            border-radius: 30px;
            border: solid 2px #1e3d83;
            background-color: #fff;
            font-size: 12px;
            font-weight: 600;
            text-align: center;
            color: #1e3d83;
        }
    }
}

.modify_style_select{
    display: block;
    width: 100px;
    height: 35px;
    padding: 10px 12px 9px 10px;
    border: none;
    border-radius: 5px;
    background-color: #999;
    mix-blend-mode:luminosity;
    filter: blur(0.2px);
    color:white;
}
.modify_reupload{
    padding: 7px;
    border-radius: 50%;
    background-color: #888;
    mix-blend-mode:luminosity;
    filter: blur(0.1px);
    color:white;
    font-size: 22px;
    margin-right: 10px;
}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;