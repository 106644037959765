.search_form {
  max-width: 1300px;
  margin-bottom: 20px;
  padding-right: 200px;
  position: relative;

  :global(.ant-form-item) {
    min-height: 36px;
    align-items: center;
  }

  :global(.ant-form-item-label) > label {
    font-size: 12px;
    font-weight: bold;
    color: #777;
  }

  :global(.ant-form-item-control-input) {
    min-height: 36px;
    :global(.ant-radio-button-wrapper) {
      line-height: 34px;
      height: 36px;
    }
  }

  .footer {
    margin-top: 10px;
  }

  .move-down {
    transform: translateY(2px);
  }
  .input_range {
    display: flex;
    align-items: center;
    .input {
      margin-right: 10px;
    }
    .input + .input {
      margin-left: 10px;
    }
  }
  .select_range {
    display: flex;
    gap: 10px;
  }
  :global(.ant-select-selector){
    width: 100px !important;
  }
}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;