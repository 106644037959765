.item_detail_div {
  .thumbnail_img {
    width: 100%;
    height: 380px;
    margin: 0 auto;
    display: flex;
    justify-content: right;

    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .input {
    border:none;

      text-align: right !important;
    & > div > input {
      font-size: 14px;
      font-weight: 500;
    }
    &:not(.title_p){
      padding-right: 0 !important;
      padding-top:0 !important;

    }

    background-color:#ffffff !important;
  }
  .ant-input-number-input {
    text-align: right !important;

  }
  .sub_title_text.input {

    text-align: right !important;
  }
  .title_p {
    font-size: 16px;
    font-weight: 600;
    text-align: left !important;

    color: #000;
    padding-left: 0;

  }
  .sub_title_text {
    font-size: 12px;
    font-weight: 500;
    //text-align: right !important;
    color: #767676;
  }
  p.sub_title_text {
    font-weight: 600;
  }

  .price {

    width: 220px;
    & > div > input {
    color: #1e3d83;
      font-weight: bold;
      font-size: 18px;
    }
  }
  .save {
    display: block;
    margin: 0 auto;
    margin-top: 15px;
    padding: 4px 24px;
    border-radius: 15px;
    border: solid 2px #1e3d83;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: #1e3d83;
    background-color: white;
  }
  .flex_div {
    margin-bottom: 11px;
    display: flex;
    justify-content: space-between;
  }
  .won{
    font-size: 10px;
  }
  .content_div {
    padding: 20px;
  }
  .sub_content_div {
    padding: 0 0 0 20px;
  }
  .content_textarea {
    margin-top: 10px;
    border: none;
    font-size: 14px;
    color: #767676;
  }
  .save_button {
    width: 104px;
    display: block;
    padding: 6px 30px;
    border-radius: 15px;
    border: solid 2px #1e3d83;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: #1e3d83;
  }
}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;