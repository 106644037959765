.user_detail {
  width: 100%;
  display: flex;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 10px 20px 0px;

  .side_line {
    width: 10px;
    background-color: #7eccde;
  }
  .content_wrapper {
    padding: 20px 50px;
  }
  .label {
    margin-top: 10px;
    height: 20px;
    background-color: #7eccde;
    padding: 3px 10px;
    font-size: 10px;
    border-radius: 3px;
    font-weight: bold;
    text-align: center;
    color: #fff;
  }
  .name {
    height: 19px;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: bold;
    color: #000;
  }
  .email {
    margin-bottom: 0;
    font-size: 12px;
    color: #767676;
  }
  .phone {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: -0.8px;
    color: #1e3d83;
  }
  .level_container {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 50px 0 50px;
    justify-content: space-evenly;
    > div {
      > span {
        font-size: 12px;
      }
    }
    p {
      width: 50px;
      height: 50px;
      padding: 0;
      background-color: #d9d9d9;
      font-size: 11px;
      text-align: center;
      border-radius: 8px;
      
      span {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
  .life_container {
    display: flex;
    padding: 30px 20px;
    justify-content: center;
  }
  .my_page_list {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    padding: 2.5px 10px;
    .user_detail_switch {
      margin-left: auto;
    }
    .more_button {
      height: 34px;
      margin-left: auto;
      padding: 5px 29px;
      font-weight: bold;
      border-radius: 15px;
      border: solid 2px #1e3d83;
      background-color: white;
      color: #1e3d83;
      &:disabled {
        border: solid 1.5px #d9d9d9;
        color: #d9d9d9;
      }
    }
    .new_item {
      margin-left: 5px;
      font-size: 14px;
      font-weight: bold;
      color: #1e3d83;
    }
  }
}
.agencies_score {
  display: flex;
  justify-content: space-between;
  height: 72px;
  padding: 10px 15px;
  background-color: #d9d9d9;
  border-radius: 10px;
  .inner_div {
    width: 55%;
    .star_p {
      text-align: right;
    }
    .star_score_p {
      font-size: 20px;
      color: #767676;
      text-align: right;
    }
    .flex_p {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .score_title_span {
      font-size: 12px;
      text-align: left;
      color: #767676;
    }
    .score_score_span {
      font-family: Roboto;
      font-size: 12px;
      font-weight: bold;
      text-align: right;
      color: #767676;
    }
  }
}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;