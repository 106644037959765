@import '~antd/dist/antd.less';

html,
body {
  font-family: 'Montserrat', 'NotoSansKR' !important;
  box-sizing: border-box;
  min-width: 1600px;
  background: #f9f9f9;
}

p, h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0px;
}

button {
  border: none;
  background-color: #fff;
}

#root {
  position: relative;
  height: 100%;
}


.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0px;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  margin-top: 0px;
  margin-bottom: 0px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent !important;
}

.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  font-size: 12px;
  font-weight: bold;
  padding: 5px 16px;
  padding-left: 48px;
}

.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding-left: 16px;
}

.ant-menu.ant-menu-sub.ant-menu-vertical {
  margin-top: -3px;
}

.ant-page-header {
  padding: 0px 0px 24px;
}

.ant-page-header-heading-sub-title {
  font-size: 11px;
}

.ant-page-header-heading-left {
  align-items: baseline;
}

.ant-table {
  font-size: 12px;
  background: transparent;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 4px 8px;
  min-height: 45px;
  height: 45px;
}

.ant-table-tbody > tr:not(.ant-table-measure-row) {
  //margin-inline: 10px;
  border-top: none;
  //
  border-bottom: 1px solid #d9d9d9;
}

//.ant-table-tbody > tr {
//  position: relative;
//  &:not(.ant-table-measure-row)::after {
//    content: '';
//    position: absolute;
//    width: 95%;
//    height: 0.5px;
//    background-color: #d2d4e1;
//    left: 2.5%;
//    bottom: 0;
//  }
//
//  &:last-of-type {
//    &::after {
//      content: none;
//    }
//  }
//}


//.ant-table-tbody > tr::after {
//  content: '';
//  position: absolute;
//  bottom: 0;
//  left: 0;
//  width: calc(90% - 20px); /* Adjust width as needed */
//  border-bottom: 1px solid #ccc;
//}

.ant-table-tbody > tr > td {
  border: none;

}

.ant-input-number-disabled .ant-input-number-input {
  text-align: right;
  padding: 0;
}

.ant-table-body, .ant-table-tbody {
  //margin-inline: 10px !important;
}

.ant-table-body table {
  border-collapse: collapse;
}

.ant-table-thead > tr > th {
  background: #fff;
  height: 50px;
  min-height: 50px;
  //border-top: 1px solid #e0e0e0;
  //border-bottom: 1px solid #333;
  text-align: center !important;

  &:first-child {
    border-left: 1px solid #e0e0e0;
  }

  &:last-child {
    border-right: 1px solid #e0e0e0;
  }
}

.ant-form-horizontal .ant-form-item-label {
  min-width: 100px;
  text-align: left;

  & > label::after {
    display: none;
  }
}

.ant-form-item {
  margin-bottom: 0px;
}

.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  background: #f9f9f9;
}

.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  padding: 0;
}

#root > div > div.BasicLayout_body__mKNE- > div > div:nth-child(2) > div:nth-child(2) > div.ant-collapse.ant-collapse-icon-position-end.ant-collapse-borderless > div > div.ant-collapse-content.ant-collapse-content-active > div {
  padding: 0;
}

.ant-table-cell-row-hover {
  background: #dce8fb !important;
}

//.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
//  height: 36px;
//}
//.ant-select-single .ant-select-selector .ant-select-selection-item,
//.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
//  line-height: 34px;
//}
//
//.ant-input-affix-wrapper {
//  min-height: 36px;
//}

//.ant-picker {
//  min-height: 36px;
//  display: flex;
//}

.ant-picker-range {
  display: flex;
}

.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 50px;
}

.ant-tree .ant-tree-treenode {
  width: 100%;
}

.ant-tree .ant-tree-node-content-wrapper {
  flex: 1;
}

.uploadlist-hide .ant-upload-list {
  display: none !important;
}

.ant-card-head {
  font-size: 14px;
  min-height: 30px;
}

.ant-card-head-title {
  padding: 12px 0px;
}

tr.drop-over-downward td {
  border-bottom: 1px solid #1890ff;
}

tr.drop-over-upward td {
  border-top: 1px solid #1890ff;
}

.calc_input .ant-select-selection-item-remove {
  display: none;
}

.calc_input .ant-tag {
  margin-right: 0px;
}

.fields {
  margin-bottom: 20px;

  & > b {
    display: block;
    margin-bottom: 5px;
  }
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
  overflow: hidden;
}

.product_modal_body {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  & > aside {
    width: 400px;
    padding: 0px;
    background: #000;
  }

  & > .body {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    .zoom_image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 99;
      background: rgba(0, 0, 0, 0.7);
      padding: 20px;
      overflow: auto;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    & > section {
      flex: 1;
      padding: 20px;
      overflow-y: scroll;
      overflow-x: hidden;
    }

    & > footer {
      width: 100%;
      overflow: hidden;
      position: relative;
    }

    & > footer > .scroll {
      width: 100%;
      min-height: 120px;
      overflow-x: scroll;
      padding: 0px 20px;

      & > div {
        display: flex;
        flex-direction: row;
      }
    }
  }
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box,
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box,
.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 !important;
}


.row-dragging .drag-visible {
  visibility: visible;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
  background: #f9f9f9;
}

.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 10px 20px;
}

.ant-descriptions-item-content {
  padding: 0px 20px;
  background: #fff;
}

.ant-descriptions-item-content input {
  margin: -10px;
}

.ant-table-pagination.ant-pagination {
  margin: 25px 0px;
  z-index: 10;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important
}

.ant-table {
  background: #fff;
}

.ant-table-row {
  cursor: pointer !important;
}

.ant-table-thead {
  .ant-table-cell {
    background-color: @table-gray-color;
  }
}

.ant-switch {
  position: relative;
  z-index: 10;
}

.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  left: 0
}

h3 {
  font-weight: 600;
}


.ant-table-pagination > * {
  border: none
}

.ant-table-pagination.ant-pagination {
  //padding-bottom: 10px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;

  border-radius: 3px;
  background-color: #efefef;

}

.ant-pagination-prev, .ant-pagination-next {
  position: absolute;
}

.ant-pagination-prev {
  margin-left: 33px;
  left: 0
}

.ant-pagination-next {
  right: 0;
  margin-right: 33px
}

.ant-select-selector {
  border: none
}


.ant-table-thead {
  > tr:nth-of-type(1) {
    > th {
      min-height: 0px;
      height: 25px;
      max-height: 25px !important;
      border-bottom: none;
    }
  }

  > tr:nth-of-type(2) {
    > th {
      padding-top: 0;
      font-size: 10px;
      color: #999;
      min-height: 0px;
      height: 25px;
      max-height: 25px !important;
    }
  }

}

.ant-table-thead th.ant-table-column-sort {
  background: #e8e8e8;
}

.ant-picker-active-bar {
  display: none;
}

.ant-input-number-group-addon {
  border: none !important;
}

.ant-input-number-disabled {
  background-color: #d9d9d9 !important;
}

.ant-input-number-group-addon {
  border-radius: 0 5px 5px 0;
  background-color: #e5e5e5 !important;
}

.ant-input-number-disabled + .ant-input-number-group-addon {

  background-color: #d9d9d9 !important;
}


.ant-input-number-input {
  text-align: right !important;

  //margin: 10px !important;


}

.ant-input-number-handler-wrap {
  display: none;
}


.ant-switch[aria-checked="false"] {
  .ant-switch-handle::before {
    background-color: #1e3d83;
  }
}


@primary-color: #1e3d83;@table-gray-color: #e8e8e8;