.category_div {
  width: 600px;
  min-height: 800px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  .category_header {
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    background-color: #e8e8e8;
    height: 40px;
    display: flex;
  }
  .category_header_title_section {
    width: 50%;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    span {
      font-size: 14px;
      font-weight: 600;
      color: #000;
    }
  }
}

//index
.category_div {
  background-color: white;

  :global(.ant-row) {
    width: 100%;
    margin: 0 !important;
    display: inline-block;
    height: 100%;
  }
  :global(.ant-col-10) {
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0 !important;
    height: 100%;
  }
  :global(.ant-card) {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
  }
  :global(.ant-card-body) {
    width: 100%;
    height: 100%;
  }
  .tree_container_div {
    padding: 0px 50px;
    height: 100%;
    :global(.ant-tree) {
      height: 100%;
    }
    :global(.ant-tree-list) {
      height: 100%;
    }
    :global(.ant-tree-list-holder) {
      height: 100% !important;
    }
  }
}
.buttonWrapper {
  width: 130px;
  height: 30px;
  display: flex;
  flex-direction: row;
  .leftButton {
    width: 50%;
    height: 100%;
    border-radius: 15px 0px 0px 15px;
    background-color: #1e3d83;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rightButton {
    width: 50%;
    height: 100%;
    border-radius: 0px 15px 15px 0px;
    background-color: #de7e7e;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .buttonText {
    font-size: 14px;
    color: #fff;
  }
}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;