.alignInline {
  display: flex;
  align-items: center;
}
.checkBtn {
  background-color: #d8d8d8;
  color: #fff;
  border: none;
  // height: 100%;
  // position: absolute;
  // right: 0;
}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;