.bid_container{
    //width: 100%;
    
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    height: 400px;
    padding: 30px 20px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 10px;

    .status_label{
        border-radius: 5px;
        display: inline-block;
        padding: 4px 7px 2px;
        background-color: #a2ce76;
        font-size: 10px;
        text-align: center;
        color: #fff;
    }
    .line{
        margin: 10px 0;
    }
    .apart_title{
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        color: #000;
        margin-left: 3px;
    }
    .small_bold_content{
        font-size: 12px;
        font-weight: 600;
        letter-spacing: -0.3px;
        color: #767676;
    }
    .small_content{
        margin: 0px 50px;
        font-size: 12px;
        letter-spacing: -0.3px;
        text-align: center;
        color: #767676;
    }
    .small_long_content{
        margin: 0px 36px !important;
    }
    .stop_btn{
        width: 100px;
        height: 30px;
        margin: 40px 0px;
        padding: 6px 20px;
        border-radius: 15px;
        background-color: #1e3d83;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        color: #fff;
    }
    .primary_text{
        font-size: 14px;
        font-weight: 600;
        color: #1e3d83;
    }
}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;