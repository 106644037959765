.basic_layout {
  display: flex;
  flex-direction: column;
  height: 100%;

  // overflow-y: hidden;
  .auth_body {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .body {
    padding: 12px 50px 0px;
    display: flex;
    height: 100%;
    justify-content: center;


    // @media (max-width: 1023px) {
    //   margin-left: 0px;
    // }

    & > header {
      border-bottom: 1px solid #ddd;
      position: fixed;
      top: 0px;
      left: 260px;
      right: 20px;
      padding: 10px 0px;
      height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 10;

      .menu_button {
        display: none;
      }

      // @media (max-width: 1023px) {
      //   left: 20px;

      //   .menu_button {
      //     display: flex;
      //   }
      // }
    }
  }

  .menu_container {
    position: sticky;
    left: 0;
    top: 0;
    width: 100%;
    min-width: 1600px;
    background-color: rgb(30, 61, 131);
    z-index: 999;

    .menu_flex {
      box-sizing: border-box;
      display: flex;
      height: 50px;
      justify-content: space-between;
      align-items: stretch;
      //width: 1440px;
      margin-left: auto;
      margin-right: auto;

      .logo {
        // padding-top: 5px;
        height: 30px;
      }
    }

    li {
      span {
        color: rgba(255, 255, 255, 0.65);
      }

      &::after {
        display: none;
      }

      &.ant-menu-submenu-selected {
        .ant-menu-title-content {
          color: white;
        }
      }
    }
  }

  :global(.ant-menu-submenu-selected) {
    :global(.ant-menu-title-content) {
      color: white;
    }
  }

  :global(.ant-menu-horizontal) {
    border-bottom: 0px;
  }

  :global(.ant-menu-root) {
    //width: 100%;
    //justify-content: center;
    li {
      padding: 0 15px !important;
    }
  }

  :global(.ant-menu-item-selected) {
    :global(.ant-menu-title-content) {
      color: white;
    }
  }
}

:global(.ant-menu-submenu) {
  :global(.ant-menu-sub) {
    background-color: rgba(#091632, 0.7);
  }

  :global(.ant-menu-item-only-child) {
    span {
      color: #aabcf8;
      font-weight: 500;
      opacity: 1;
    }

    &:hover {
      span {
        color: white;
      }
    }
  }
}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;