.infoWrap {
  display: flex;
  justify-content: space-between;
  gap: 10;
}
.infoContainer {
  display: flex;
  * + * {
    margin-right: 50px;
  }
}
.dflex {
  display: flex;
  gap: 10px;
  h2 {
    font-size: 16px;
  }
}

.switchWrap {
  margin: 30px 0;
  * + * {
    margin-left: 10px;
  }
}
.sum {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;