.statistic_container {
  background-color: white;
  padding: 50px 20px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 10px 20px 0px;
  margin-bottom: 10px;
  border-radius: 10px;

  .statistic_card_wrapper {
    display: flex;
    justify-content: space-between;
    background-color: #e8e8e8;
    border-radius: 10px;
  }
  .user_label {
    display: inline-block;
    width: 60px;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 2.67;
    color: #767676;
  }
  .bold_text {
    font-size: 10px;
    font-weight: bold;
    line-height: 3.2;
    color: #767676;
  }
  .thin_text {
    font-size: 10px;
    font-weight: 300;
    line-height: 3.2;
    letter-spacing: -0.5px;
    color: #767676;
  }
}
.statistics_detail_card {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  .title {
    font-size: 14px;
    color: #767676;
  }
  .select_label {
    margin-right: 10px;
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    color: #767676;
  }
  select {
    padding: 2px 5px;
  }
}
.map_card {
  width: 22%;
  height: 100px;
  padding: 6px 9px;
  background-color: #e8e8e8;
  border-radius: 10px;
  .sum_member {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -1.2px;
  }
  .sub_text {
    font-size: 10px;
    letter-spacing: -0.5px;
    color: #767676;
  }
  .sum_num {
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    color: #767676;
    text-align: center;
    margin-bottom: 0;
  }
  hr {
    margin: 0;
  }
  .member_label {
    margin-top: 10px;
    text-align: center;
    font-size: 11px;
    font-weight: 600;
    color: #767676;
  }
}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;