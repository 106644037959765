.location_container {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 10px;
  .backdrop {
    position: absolute;
    background-color: '#FFF';
    width: 100%;
    height: 100%;
    z-index: 99;
  }
  .image_div {
    width: 100%;
    height: 220px;
    position: relative;
    // background-color: black;
  }
  .content_box_div {
    padding: 10px 20px;
  }
  .status_label {
    display: inline-block;
    padding: 4px 7px 2px;
    background-color: #a2ce76;
    font-size: 10px;
    text-align: center;
    color: #fff;
  }
  .line {
    margin: 0;
  }
  .apart_title {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #000;
    margin-left: 3px;
  }
  .small_bold_content {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.3px;
    color: #767676;
  }
  .small_content {
    margin: 0px 50px;
    font-size: 12px;
    letter-spacing: -0.3px;
    text-align: center;
    color: #767676;
  }
  .small_long_content {
    margin: 0px 36px !important;
  }
  .naver_more_btn {
    width: 100px;
    height: 30px;
    margin-top: 10px;
    padding: 6px 12px;
    border-radius: 15px;
    border: solid 2px #1e3d83;
    font-size: 11px;
    font-weight: 600;
    text-align: center;
    color: #1e3d83;
    white-space: nowrap;
  }
  .primary_text {
    font-size: 14px;
    font-weight: 600;
    color: #1e3d83;
  }
}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;