.small_estimate_container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  //height: 400px;
  background-color: rgba(0, 0, 0, 0);

  .profile_div {
    display: flex;
    align-items: center;
    .profile_image_div {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      //background-color: black;
    }
    .profile_content_div {
      margin-left: 10px;
      .profile_title {
        font-size: 12px;
        color: #767676;
      }
      .profile_name {
        font-size: 16px;
        font-weight: 600;
        color: #000;
      }
    }
  }
  .flip_card_div {
    //position: absolute;
    top: 0;
    display: flex;
    width: 100%;
    height: 90px;
    padding: 0 20px 0 0;
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.08);
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    transition: height 0.5s ;
  }
  .side_line {
    width: 15px;
    background-color: #7eccde;
  }
  .label {
    margin-top: 10px;
    width: 80px;
    height: 18px;
    background-color: #7eccde;
    padding: 3px 6px;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    color: #fff;
  }
  .name {
    height: 19px;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: bold;
    color: #000;
  }
  .email {
    margin-bottom: 0;
    font-size: 12px;
    color: #767676;
  }
  .phone {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: -0.8px;
    color: #1e3d83;
  }
  .life_container {
    display: flex;
    flex-wrap: wrap;
    padding: 30px 20px;
    .life_item {
      font-size: 12px;
      line-height: 2.67;
      color: #767676;
      white-space: nowrap;
    }
  }
  .content_box {
    padding: 0 20px;
    font-size: 14px;
    text-align: left;
    color: #767676;
  }
  .flip_card_price_p {
    text-align: right;
    span {
      font-family: Roboto;
      font-size: 16px;
      font-weight: bold;
      color: #1e3d83;
    }
  }
}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;