.slider_div {
  position: relative;
  height: 220px;
  background-image: url(../../../../src/assets/dummy/01/01-07.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  &.slider_div_no_data{
    background-color: black;
  }
  .backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.71), rgba(84, 84, 84, 0));
  }
  .slider_inner_div {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px 20px;
  }
}
.category_p {
  font-size: 12px;
  color: #fff;
}
.title_p {
  margin: 2px 0 29px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}
.desc_p{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-wrap:break-word;
}
.flex_div {
  display: flex;
  justify-content: space-between;
}
.style_profile_img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.style_button_img {
  width: 20px;
  height: 20px;
  margin-left: auto;
  margin-right: auto;
}
.style_button_count_p {
  font-size: 12px;
  font-weight: 500;
  line-height: 2;
  letter-spacing: -0.6px;
  color: #fff;
}
.style_condition_div{
    width: 72px;
    height: 72px;
    padding: 13.7px;
    border-radius: 10px;
    background-color: #1e3d83;
    text-align: center;
}
.style_condition_p{
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    white-space: nowrap;
}
.style_condition_label_span{
    width: 72px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #1e3d83;
}
.style_condition_list{
    margin-top: 30px;
    ul{
        list-style: none;
        padding-left: 0;
        li{
            display: flex;
            align-items: center;
            margin: 5px 0px;
            span:first-child{
                margin-right: 5px;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: #767676;
            }
            span:last-child{
                font-size: 14px;
                text-align: left;
                color: #767676;
            }
        }
    }
}
.style_category_div{
    margin-top: 90px;
    .style_category_title{
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        color: #000;
    }
}
.style_section_div{
    margin-top: 120px;
    .title_p{
        font-size: 16px;
        font-weight: 600;
        color: #000;
        margin-bottom: 5px;
    }
    .style_section_main_img{
        display: block;
        height: 400px;
        margin-left: auto;
        margin-right: auto;
    }
    .style_section_desc_p{
        margin-top: 10px;
        font-size: 14px;
        line-height: 1.57;
        color: #767676;
    }
}

.trand_card_div{
  padding: 10px 18px;
  .trand_thumbnail_img{
    position: relative;
    width: 40px;
    height: 40px;
    padding: 2px;
    background-color: white;
    border-radius: 5px;
    img{
      border-radius: 5px;
    }
  }
  > button{
    display: block;
    margin: 0 auto;
    margin-top: 15px;
    padding: 4px 24px;
    border-radius: 15px;
    border: solid 2px #1e3d83;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: #1e3d83;
    background-color: white;
  }
}
.label_wrapper_div{
  display: flex;
  flex-wrap: wrap;
  > p{
      margin: 0px 3.5px 7px;
      padding: 3px 10px;
      border-radius: 30px;
      border: solid 2px #1e3d83;
      background-color: #fff;
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      color: #1e3d83;
  }
}
.modify_input_wrapper_div{
  display: flex;
  border-bottom: 1px solid #d9d9d9;
  .modify_title_input{
      width: 90%;
      border: none;
      background-color: #f9f9f9;
      font-size: 16px;
      font-weight: 600;
      color: #000;
  }
  > p{
      width: 10%;
      text-align: center;
  }
}
:global(.ant-modal-body) {
  padding:0;
}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;