.small_user_container {
  display: flex;
  width: 100%;
  height: 400px;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 10px;
  
  .side_line {
    width: 15px;
    background-color: #7eccde;
  }
  .label {
    margin-top: 10px;
    width: 80px;
    height: 18px;
    background-color: #7eccde;
    padding: 3px 6px;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    color: #fff;
  }
  .name {
    height: 19px;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: bold;
    color: #000;
  }
  .email {
    margin-bottom: 0;
    font-size: 12px;
    color: #767676;
  }
  .phone {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: -0.8px;
    color: #1e3d83;
  }
  .life_container{
    display: flex;
    flex-wrap: wrap;
    padding: 30px 20px;
    .life_item{
        font-size: 12px;
        line-height: 2.67;
        color: #767676;
        white-space: nowrap;
    }
  }
  .content_box{
    padding: 0 20px;
    font-size: 14px;
    text-align: left;
    color: #767676;
  }

}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;