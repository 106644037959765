.switch_field {
  display: flex;
  align-items: center;
  & > span {
    flex: 1;
    text-align: left;
  }
}
.switch {
  width: 40px;
  margin-inline: 5px;
  position: relative;
  top: -1px;
}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;