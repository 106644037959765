.filterWrapper {
  display: flex;
  align-items: center;
  margin-left: 20px;
  .filterText {
    margin-left: 4px;
    font-size: 14px;
    color: #767676;
  }
}
.switchWrapper {
  transform: scaleX(-1);
}
.detailWrapper {
  padding: 60px 0px;
  width: 100%;
  // height: 100%;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 10px;
  border-radius: 10px;
  background-color: #fff;
}
.detailTitleWrapper {
  display: flex;
  flex-direction: column;
  padding: 0px 30px;
  margin-bottom: 15px;
}
.imageWrapper {
  width: 100%;
  height: 250px;
  object-fit: fill;
}

.contentWrapper {
  margin-top: 40px;
  padding: 0px 30px;
}
.content {
  color: #767676;
  font-weight: 500;
}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;