.comment_count_p{
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #000;
    span{
        color: #1e3d83;
    }
}
.comment_profile_div{
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: flex-start;
    > img{
        width: 36px;
        height: 36px;
        border-radius: 50%;
    }
    .no_img_div{
        width: 36px;
        height: 36px;
        border-radius: 50%;
    }
    span{
        margin-left: 10px;
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        color: #000;
    }
}
.comment_input_div{
    display: flex;
    border-bottom: 1px solid #d9d9d9;
    margin: 30px 0;
     > input{
        width: 90%;
        border: none;
        background-color: #f9f9f9;
        font-size: 16px;
        font-weight: 600;
        color: #000;
    }
    > button{
        width: 15%;
        text-align: center;
        border-radius: 15px;
        border: solid 2px #1e3d83;
        font-size: 12px;
        font-weight: 400;
        color: #1e3d83;
        background-color: #f9f9f9;
        margin-bottom: 2px;
    }
}
.comment_content_div{
    margin: 17px 0;
    >p{
        margin-left: 46px;
        span{
            font-size: 14px;
            font-weight: 600;
            line-height: 1.57;
            color: #767676;
            &.contour{
                display: inline-block;
                margin: 0 5px;
            }
        }
    }
    .comment_line_p{
        font-size: 14px;
        letter-spacing: -0.35px;
        text-align: left;
        color: #767676;
    }
}
.comment_reply{
    margin-left: 46px;
}
.comment_button{
    cursor: pointer;
}
@keyframes checkUpdate {
    0%{
      background-color: rgba(#1e3d83,0);
    }
    100%{
      background-color: rgba(#1e3d83,0.4);
    }
  }

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;