.signupContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  height: 100%;
  width: 100%;
  max-width: 500px;
  overflow: scroll;
}
.signupBodyWrapper {
  display: flex;
  flex-direction: column;
  width: 330px;
  padding-top: 70px;
  padding-bottom: 50px;
}
.sectionWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
.businessLicenseWrapper {
  margin-top: 10px;
  width: 100%;
  height: 330px;
  border-radius: 10px;
  background-color: #d9d9d9;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}
.termsWrapper {
  margin-top: 10px;
  width: 330px;
  height: 165px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 10px 20px 0 rgba(0; 0; 0; 0.08);
  background-color: #fff;
  span {
    font-size: 14px;
    color: #767676;
  }
}
.termsTopWrapper {
  width: 100%;
  height: 50px;
  background-color: #e8e8e8;
  padding: 15px 20px;
}
.termsBottomWrapper {
  width: 100%;
  height: 115px;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.optionalInformationWrapper {
  margin-top: 60px;
}
.optionalWrapper {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.optionalItemWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.textarea {
  border: none;
  outline: none;
  background-color: inherit;
}
.textarea::placeholder {
  font-size: 14px;
  color: #d9d9d9;
}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;