.preview_text{
    font-size: 12px;
    color: #767676;
}
.main_carousel_wrapper_div{
    display: flex;
    justify-content: space-between;
    .main_carousel_div{
        width: 60px;
        height: 60px;
        background-color: #e8e8e8;
        border-radius: 5px;
    }
    .main_middle_size_div{
        width: 80px;
        height: 80px;
        background-color: #e8e8e8;
        border-radius: 5px;
    }
}
.main_large_carousel_wrapper_div{
    white-space: nowrap;
    overflow: hidden;
    .main_large_size_div{
        display: inline-block;
        width: 100px;
        height: 100px;
        background-color: #e8e8e8;
        border-radius: 5px;
        margin-left: 20px;
        &:first-child{
            margin-left: 0;
        }
    }
}
.main_footer_banner{
    width: 100%;
    height: 60px;
    border-radius: 5px;
    background-color: #e8e8e8;
}
.modal_sub_title_div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 115px;
    span{
        font-size: 16px;
        font-weight: 600;
        color: #000;
    }
    button{
        padding: 8px 29px;
        border-radius: 20px;
        border: solid 2px #1e3d83;
        background-color: #1d3b7e;
        color:white
    }
}
.modal_content_line_div{
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    .content_img_div{
        position: relative;
        width: 50px;
        height: 50px;
        img{
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }
        >button{
            position: absolute;
            top: -5px;
            right: -5px;
            width: 20px;
            height: 20px;
            padding: 0;
            background-color: rgba(0,0,0,0);
        }
    }
    .contetn_input_div{
        width: 50%;
        input{
            width: 100%;
            border: none;
            border-bottom: 1px solid #d9d9d9;
            &:first-child{
                font-size: 12px;
                letter-spacing: -0.6px;
                text-align: left;
                color: #767676;
            }
            &:last-child{
                font-size: 12px;
                font-weight: 600;
                letter-spacing: -0.6px;
                text-align: right;
                color: #767676;
            }
        }
    }
    .content_add_button_div{
        p{
            font-size: 12px;
            font-weight: 600;
            letter-spacing: -0.6px;
            text-align: center;
            color: #767676;
        }
        button{
            padding: 8px 23px;
            border-radius: 20px;
            border: solid 2px #1e3d83;
            background-color: #fff;
            font-size: 12px;
            font-weight: 600;
            text-align: center;
            color: #1e3d83;
        }
    }
}
.search_plus_button{
    transition: color 0.15s linear;
    &:hover{
        color:#1e3d83 !important;
    }
}
.section {

}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;