.alignInline {
  display: flex;
  align-items: center;
}

.tableContainer {
  display: flex;
}
.profileTable {
  width: 100%;
  & th {
    width: 120px;
    background-color: #f2f2f2;
    padding: 0;
  }
  & td {
    text-align: center;
    padding: 0;
  }
  & td:last-child {
    width: 150px;
  }
}
.checkBtn {
  background-color: #d8d8d8;
  color: #fff;
  border: none;
  height: 100%;
  position: absolute;
  right: 0;
}
.detailBtn {
  background-color: #d8d8d8;
  border: none;
  width: 100%;
}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;