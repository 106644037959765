.modalContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 30px 25px;
}

.leftContainer {
  width: 35%;
  height: 100%;
}

.rightContainer {
  width: 65%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.titleText {
  font-size: 18px;
  font-weight: 500;
  color: #000;
}
.filterWrapper {
  position: relative;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.filterItemWrapper {
  display: flex;
  flex-direction: column;
}
.categoryName {
  font-size: 10px;
  font-weight: 500;
  color: #767676;
  margin-left: 3px;
}
.buttonWrapper {
  position: absolute;
  display: flex;
  flex-direction: row;
  right: 0;
  gap: 10px;
}
.itemListContainer {
  position: relative;
  height: 70%;
  margin-top: 40px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 30px;
  border-radius: 20px;
}
.itemListWrapper {
  padding: 20px;
  padding-bottom: 40px;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  overflow-y: scroll;
}
.itemListFooterWrapper {
  z-index: 99;
  position: absolute;
  background-color: #fff;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 0px 0px 20px 20px;
  bottom: 0;
  :global(.ant-pagination-item) {
    background-color: #e6e6e6;
    border: none;
  }
  :global(.ant-pagination-item-active) {
    background-color: #1e3d83;
  }
  :global(.ant-pagination-item-active a) {
    background-color: #1e3d83;
    color: #fff;
  }
}
.productItemWrapper {
  position: relative;
  width: 170px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: 20px;
}
.productImage {
  width: 100%;
  height: 170px;
  border-radius: 10px;
  object-fit: cover;
  transform: 0.2s ease all;
}
.productCategoryText {
  margin-top: 10px;
  font-size: 13px;
  color: #767676;
}
.productTitleText {
  font-size: 13px;
  font-weight: 700;
  color: #000;
}
.productWriterText {
  font-size: 12px;
  color: #767676;
}
.rowWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.priceText {
  font-size: 11px;
  color: #767676;
}
.checked {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 98;
  position: absolute;
  right: 5px;
  top: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #1e3d83;
}
.itemCardListWrapper {
  padding-top: 10px;
  position: relative;
  width: 100%;
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  gap: 20px;
  margin-top: 20px;
}
.productCardWrapper {
  padding: 10px;
  position: relative;
  min-width: 300px;
  height: 100px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 30px;
  border-radius: 10px;
}
.deleteWrapper {
  position: absolute;
  right: -5px;
  top: -5px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #1e3d83;
  border-radius: 50%;
}
.productCardImage {
  width: 80px;
  height: 80px;
  border-radius: 6px;
  object-fit: cover;
}
.productCardInfoWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.thumbnailContainer {
  padding: 0px 20px;
  position: relative;
  height: 70%;
  margin-top: 95px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;