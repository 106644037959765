.alignInline {
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkBtn {
  background-color: #d8d8d8;
  color: #fff;
  border: none;
}
.slider_div {
  position: relative;
  width: 100%;
  height: 360px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  overflow: hidden;
  .backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.71), rgba(84, 84, 84, 0));
  }
  .slider_inner_div {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px 20px;
  }
}
.category_p {
  font-size: 12px;
  color: #fff;
}
.title_p {
  margin: 2px 0 29px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}
.flex_div {
  display: flex;
  justify-content: space-between;
}
.style_profile_img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.style_button_img {
  width: 20px;
  height: 20px;
  margin-left: auto;
  margin-right: auto;
}
.style_button_count_p {
  font-size: 12px;
  font-weight: 500;
  line-height: 2;
  letter-spacing: -0.6px;
  color: #fff;
}
@primary-color: #1e3d83;@table-gray-color: #e8e8e8;