.filterWrapper {
  display: flex;
  align-items: center;
  margin-left: 20px;
  .filterText {
    margin-left: 4px;
    font-size: 14px;
    color: #767676;
  }
}
.switchWrapper {
  transform: scaleX(-1);
}
.detailWrapper {
  //padding: 60px 0px;
  width: 100%;
   max-height: 400px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 10px;
  border-radius: 10px;
  background-color: #fff;
}
.detailTitleWrapper {
  display: flex;
  flex-direction: column;
  padding: 0px 30px;
  margin-bottom: 15px;
}
.imageWrapper {
  width: 100%;
  height: 250px;
  border-radius: 10px;
  object-fit: fill;
}

.contentWrapper {
  margin-top: 40px;
  padding: 0px 30px;
}
.content {
  color: #767676;
  font-weight: 500;
}
.contentInfo{
  margin-top: 15px;
    display: flex;
  justify-content: space-between;

}
.titleWrapper:not(&:first-of-type){
  margin-top: 15px;
  display: flex;
  flex-direction: column;

}
.profileWrapper{
    display: flex;
    width: min-content;
    align-items: center;
    justify-content: flex-start;
}
.nicknameWrapper{
  margin-left: 5px;

 }
.commentIconWrapper{
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  align-items: center;
  & > p{
    margin-top: 10px;

  }

}
.style_button_count_p {
  font-size: 12px;
  font-weight: 500;
  line-height: 2;
  letter-spacing: -0.6px;
  color:rgb(153, 153, 153);
}

.description{
  margin-block: 10px;
  font-weight: 500;
  line-height: 1.5;
  color:rgb(153, 153, 153);
}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;