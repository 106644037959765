.preview_text{
    font-size: 12px;
    color: #767676;
}
.main_carousel_wrapper_div{
    display: flex;
    justify-content: space-between;
    .main_carousel_div{
        width: 60px;
        height: 60px;
        background-color: #e8e8e8;
        border-radius: 5px;
    }
}
.main_footer_banner{
    width: 100%;
    height: 60px;
    border-radius: 5px;
    background-color: #e8e8e8;
}
@primary-color: #1e3d83;@table-gray-color: #e8e8e8;