.alignInline {
  display: flex;
  align-items: center;
}
.profileTable {
  tr {
    border-bottom: 1px solid #ededed;
  }
  th {
    background-color: #f2f2f2;
    width: 200px;
  }
  td {
    padding: 0;
    flex-basis: 0;
    text-align: center;
  }

  .checkBtn {
    background-color: #d8d8d8;
    border: none;
    color: #fff;
  }
  .detailBtn {
    width: 100%;
    background-color: #d8d8d8;
    border: none;
    font-weight: 'bold';
    // &:hover {
    //   color: #fff;
    //   background-color: #4c72f1;
    // }
    &:active {
      opacity: 0.85;
    }
  }
}

@primary-color: #1e3d83;@table-gray-color: #e8e8e8;